import styled from 'styled-components'

export default styled.div`
  .map-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .loading {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
