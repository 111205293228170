import React from 'react'
import PropTypes from 'prop-types'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import ProgressiveImage from '../ProgressiveImage/dynamic'
import MapStyled from './styled'

import MapSrc from '../../assets/images/map.jpg'
import MapSmallSrc from '../../assets/images/map-small.jpg'

import { withTranslation } from '../../../i18n'

export class MapComponent extends React.PureComponent {
  renderMap() {
    let content = <ProgressiveImage id="img-park-map" placeHolderImgSrc={MapSmallSrc} src={MapSrc} />
    if (this.props.loading) {
      content = <SkeletonAnimation id="map-skeleton-loading" className="loading" />
    }
    return <div className="map-wrapper">{content}</div>
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('park_map')} id={'header-park-map'}>
        {this.renderMap()}
      </CardWrapper>
    )
  }

  render() {
    return <MapStyled className={this.props.className}>{this.renderContent()}</MapStyled>
  }
}

MapComponent.defaultProps = {
  loading: false,
}

MapComponent.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
}

export default withTranslation()(MapComponent)
